import React, { MouseEvent, useState } from "react";
import { HexColorPicker } from "react-colorful";

import { setUserTheme } from "@/actions/set-user-theme";
import Button from "@/components/button";
import { ButtonBar, Module } from "@/components/layouts";
import { ButtonGroup } from "@/components/layouts/button-group";
import { useCurrentUser } from "@/components/user-context-provider/user-context-provider";
import { UserPill } from "@/components/user-pill/user-pill";
import { useModal } from "@/hooks/use-modal";
import { backgroundGradient } from "@/lib/css/background-gradient";

import ModalTitle from "./modal-title";

const themeParts = [
  { key: "bg1", text: "Background 1", default: "#ff0000" },
  { key: "bg2", text: "Background 2", default: "#0000ff" },
  { key: "username", text: "Username", default: "#eeeeee" },
] as const;

const ThemeModal = () => {
  const { closeModal } = useModal();
  const currentUser = useCurrentUser();
  const [currentColourIndex, setCurrentColourIndex] = useState("bg1");
  const [palette, setPalette] = useState(
    themeParts.reduce<Record<string, string>>((palette, themePart) => {
      palette[themePart.key] =
        (currentUser?.theme?.[themePart.key] ||
          themeParts.find((t) => t.key === themePart.key)?.default) ??
        "";
      return palette;
    }, {}),
  );

  if (!currentUser) return null;

  const onSwatchPressed = (e: MouseEvent<HTMLButtonElement>) => {
    setCurrentColourIndex(e.currentTarget.dataset.colourIndex ?? "");
  };

  const onDonePressed = async () => {
    await setUserTheme(palette);
    closeModal();
  };

  const onColorChange = (newHex: string) => {
    setPalette({ ...palette, [currentColourIndex]: newHex });
  };

  const renderSwatches = () => {
    return themeParts.map((themePart, index) => (
      <Button
        key={themePart.key}
        onClick={onSwatchPressed}
        data-colour-index={themePart.key}
        active={currentColourIndex === themePart.key}
        type={
          (index === 0 && "grouped-l") ||
          (index === themeParts.length - 1 && "grouped-r") ||
          "grouped-m"
        }
      >
        <i
          className="fas fa-circle"
          style={{ color: palette[themePart.key] }}
        />
        &nbsp;{themePart.text}
      </Button>
    ));
  };

  return (
    <div>
      <ModalTitle>Customise Your Theme</ModalTitle>
      <Module>
        <div
          className="flex flex-col items-center justify-around rounded-md shadow-md border border-gray-200"
          style={{
            ...backgroundGradient(palette.bg1, palette.bg2),
            width: "100%",
            height: "200px",
          }}
        >
          <UserPill user={{ ...currentUser, theme: { ...palette } }} />
        </div>
        <div className="py-2 flex items-center justify-center">
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            {renderSwatches()}
          </span>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
          }}
        >
          <HexColorPicker
            color={palette[currentColourIndex]}
            onChange={onColorChange}
          />
        </div>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            onClick={onDonePressed}
            type="positive"
            icon="fas fa-check-circle"
            rightAlign
          >
            Done
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default ThemeModal;
