"use client";
import React from "react";

import { signOut } from "@/actions/sign-out";
import Button from "@/components/button";
import DropdownMenu from "@/components/dropdown-menu";
import { UserPill } from "@/components/user-pill/user-pill";
import { useModal } from "@/hooks/use-modal";
import ThemeModal from "@/modal/theme";

import ChooseAvatarModal from "../modal/components/choose-avatar";
import UserDetailsModal from "../modal/components/user-details";
import { useCurrentUser } from "../user-context-provider/user-context-provider";

const UserToolbar = () => {
  const currentUser = useCurrentUser();
  const { openModal } = useModal();

  if (!currentUser) {
    return (
      <Button icon="fas fa-user" href="/sign-in" data-testid="sign-in-button">
        Sign-in
      </Button>
    );
  }

  return (
    <DropdownMenu
      data-testid="user-menu-button"
      button={{
        icon: currentUser.avatarUrl ? undefined : "fas fa-user",
        text: <UserPill user={currentUser} small nonInteractive stillImage />,
        type: "transparent",
      }}
      items={[
        {
          icon: "fas fa-user-cog",
          onClick: () => openModal(() => <UserDetailsModal />),
          text: "My account",
        },
        {
          icon: "fas fa-portrait",
          onClick: () => openModal(() => <ChooseAvatarModal />),
          text: "Profile picture",
        },
        {
          icon: "fas fa-palette",
          onClick: () => {
            openModal(() => <ThemeModal />);
          },
          text: "Edit my theme",
        },
        {
          icon: "fas fa-sign-out-alt",
          onClick: async () => {
            await signOut();
          },
          text: "Sign out",
          buttonProps: {
            "data-testid": "user-sign-out-button",
          },
        },
      ]}
    />
  );
};

export default UserToolbar;
