"use client";
import React, { FC } from "react";
import { Transition } from "@headlessui/react";

import { useModal } from "@/hooks/use-modal";
import ModalInner from "@/modal/modal-inner";

export type HideModalCallback = () => void;

const Modal = () => {
  const { component, closeModal, isOpen } = useModal();

  const dismissable = true; // FIXME

  return (
    <Transition show={isOpen}>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div
          className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
          style={{ backdropFilter: "blur(5px)" }}
        >
          {dismissable ? (
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div
                className="absolute inset-0 bg-gray-500 opacity-75"
                onClick={closeModal}
              />
            </div>
          ) : (
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 transition-opacity"
                aria-hidden="true"
              >
                <div className="absolute inset-0 bg-gray-500 opacity-75" />
              </div>
            </Transition.Child>
          )}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <ModalInner>{isOpen && component}</ModalInner>
        </div>
      </div>
    </Transition>
  );
};

export default Modal;
