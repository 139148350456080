"use client";
import React, { memo, useEffect } from "react";
import { useQuery } from "@apollo/client";

import Button from "@/components/button";
import { NOTIFICATIONS_COUNT } from "@/gql/queries/notifications-count";

const POLL_TIME = 60000;

const Notifications = memo(() => {
  const {
    startPolling,
    stopPolling,
    data = { notificationsCount: 0 },
  } = useQuery(NOTIFICATIONS_COUNT, {
    pollInterval: POLL_TIME,
    errorPolicy: "ignore",
  });

  useEffect(() => {
    startPolling(POLL_TIME);
    return () => {
      stopPolling();
    };
  });

  const notificationsCount = data.notificationsCount ?? 0;

  return (
    <Button
      type={notificationsCount > 0 ? "negative" : "inactive"}
      href="/notifications"
      icon={notificationsCount > 0 ? "fas fa-bell" : "far fa-bell"}
    >
      {notificationsCount}
    </Button>
  );
});

Notifications.displayName = "Notifications";

export default Notifications;
