import crypto from "crypto";
import sha1 from "sha1";
import { v4 } from "uuid";

const encryptToken = (token?: string): [string, string, string] | null => {
  if (!token) return null;

  if (!process.env.TOKEN_ENCRYPTION_KEY)
    throw new Error("TOKEN_ENCRYPTION_KEY must be defined");

  if (!process.env.TOKEN_IV_SALT)
    throw new Error("TOKEN_IV_SALT must be defined");

  const iv = sha1(`${v4()}-${process.env.TOKEN_IV_SALT}`);

  const cipher = crypto.createCipheriv(
    "aes-256-gcm",
    process.env.TOKEN_ENCRYPTION_KEY,
    iv,
  );

  let encrypted = cipher.update(token, "utf8", "hex");
  encrypted += cipher.final("hex");
  const authTag = cipher.getAuthTag();

  return [encrypted, authTag.toString("hex"), iv];
};

const decryptToken = (tokenTuple: [string, string, string] | null) => {
  if (typeof window !== "undefined" || tokenTuple === null) return null;

  if (!process.env.TOKEN_ENCRYPTION_KEY)
    throw new Error("TOKEN_ENCRYPTION_KEY must be defined");

  if (!process.env.TOKEN_IV_SALT)
    throw new Error("TOKEN_IV_SALT must be defined");

  const [encryptedToken, authTag, iv] = tokenTuple;
  const tag = Buffer.from(authTag, "hex");

  // create a decipher object
  const decipher = crypto.createDecipheriv(
    "aes-256-gcm",
    process.env.TOKEN_ENCRYPTION_KEY,
    iv,
  );

  decipher.setAuthTag(tag);

  // decrypt the encrypted text
  let decrypted = decipher.update(encryptedToken, "hex", "utf8");
  decrypted += decipher.final("utf8");
  return decrypted;
};

export { decryptToken, encryptToken };
