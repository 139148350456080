"use client";
import { ApolloLink, HttpLink } from "@apollo/client";
import {
  ApolloNextAppProvider,
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support/ssr";

import config from "@/lib/config";
import { decryptToken } from "@/lib/token-encryption";

// Horrible hack for SSR client components
// See: https://github.com/vercel/next.js/discussions/59303
const getMakeClient = (token?: string | null) => {
  return () => {
    let httpLink: HttpLink;

    if (token) {
      httpLink = new HttpLink({
        uri: `${config.apiUrl}/graphql`,
        headers: {
          cookie: `animator_user=${token};`, // SSR gets decryted token
        },
      });
    } else {
      httpLink = new HttpLink({
        uri: `${config.apiUrl}/graphql`,
        credentials: "include", // Web clients use built in cookie credentials
      });
    }

    return new NextSSRApolloClient({
      cache: new NextSSRInMemoryCache(),
      link:
        typeof window === "undefined"
          ? ApolloLink.from([
              new SSRMultipartLink({
                stripDefer: true,
              }),
              httpLink,
            ])
          : httpLink,
    });
  };
};

export function ApolloWrapper({
  children,
  tokenTuple,
}: React.PropsWithChildren & { tokenTuple: [string, string, string] | null }) {
  const decryptedToken = decryptToken(tokenTuple);

  return (
    <ApolloNextAppProvider makeClient={getMakeClient(decryptedToken)}>
      {children}
    </ApolloNextAppProvider>
  );
}

export default getMakeClient();
